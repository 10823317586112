.range-slider__value2 {
  display: inline-block;
  position: relative;
  width: 45px!important;
  color: #000;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0px!important;
  margin-left: 8px;
}
.range-slider__value2:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid rgb(125, 115, 210);
  border-bottom: 7px solid transparent;
  content: '';
}


div#calculator  input[type=message]{
	background-color: white!important;
}

.body2{
	background-color: white;
}

.form_style2 {
    max-width: 640px;
    margin: 30px auto 0;
}