/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YjgX7Motmp5r61.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YjiH7Motmp5r61.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizHREVNn1dOx-zrZ2X3pZvkTiUa41Yjg37Motmp5r61.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizHREVNn1dOx-zrZ2X3pZvkTiUa41Yjgn7Motmp5r61.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YjjH7Motmp5g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oym1TpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oym13pjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oym1bpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oym1fpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oym1npjfGj7oY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xm1TpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xm13pjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xm1bpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xm1fpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xm1npjfGj7oY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTiUa6zgTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTiUa6zETjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTiUa6zoTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTiUa6zsTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTiUa6zUTjnTLgNs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywm1TpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywm13pjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywm1bpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywm1fpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywm1npjfGj7oY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3m1TpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3m13pjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3m1bpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3m1fpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3m1npjfGj7oY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442m1TpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442m13pjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442m1bpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442m1fpjfGj7oaMBg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442m1npjfGj7oY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTi186zgTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTi186zETjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTi186zoTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTi186zsTjnTLgNuZ5w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizBREVNn1dOx-zrZ2X3pZvkTi186zUTjnTLgNs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-iI5q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-iIwq1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-iI7q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-iI6q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-iI0q1vjitOh.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi20-SI5q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi20-SIwq1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi20-SI7q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi20-SI6q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi20-SI0q1vjitOh.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Serif'), local('IBMPlexSerif'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizDREVNn1dOx-zrZ2X3pZvkTiUS2zcZiVbJsNo.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Serif'), local('IBMPlexSerif'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizDREVNn1dOx-zrZ2X3pZvkTiUb2zcZiVbJsNo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Serif'), local('IBMPlexSerif'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizDREVNn1dOx-zrZ2X3pZvkTiUQ2zcZiVbJsNo.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Serif'), local('IBMPlexSerif'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizDREVNn1dOx-zrZ2X3pZvkTiUR2zcZiVbJsNo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Serif'), local('IBMPlexSerif'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizDREVNn1dOx-zrZ2X3pZvkTiUf2zcZiVbJ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3s-CI5q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3s-CIwq1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3s-CI7q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3s-CI6q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3s-CI0q1vjitOh.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yI5q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yIwq1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yI7q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yI6q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yI0q1vjitOh.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi2k_iI5q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi2k_iIwq1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi2k_iI7q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi2k_iI6q1vjitOh3oc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url(https://fonts.gstatic.com/s/ibmplexserif/v3/jizAREVNn1dOx-zrZ2X3pZvkTi2k_iI0q1vjitOh.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'glyphicons-halflings-regular';
  src: local('glyphicons-halflings-regular'), url(../fonts/glyphicons-halflings-regular.svg) format('svg');
  src: local('glyphicons-halflings-regular'), url(../fonts/glyphicons-halflings-regular.eot) format('eot');
  src: local('glyphicons-halflings-regular'), url(../fonts/glyphicons-halflings-regular.ttf) format('ttf');
}
