.body2{
  background-color: white;
}
.container2 {
    position: relative;
    width: 100%;
}
.container2 img {max-width: 1000px;}

.container2 .content {
    position: absolute;
    bottom: 0;
    opacity: 0.7;
    background: #332ba9;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
}
.risk_images{
    height: 270px;
}
.risk_images div{
			width: 0px;
    height: 100%;
}
.risk_images img{
    max-height: 270px;
    height: auto;
    width: auto;
    max-width: 270px;
}
.btn-success{
	color: white!important;
}

a.anchor_tag { color: rgb(125, 115, 210) !important;  }
a.anchor_tag:visited { color: rgb(125, 115, 210);}
a.anchor_tag:focus {color: #332ba9!important;}


.blue-bg{
    background-color: rgb(222, 222, 255);
}

.nav_index{
	z-index: 1021;
}
.white-bg{
   border-bottom: 2px solid rgb(125, 115, 210);
       background-color: white;
}
.child_span:hover {
    cursor: pointer;
    text-decoration: underline
}
.center {
    text-align: center;
}
.other{
    color: #332ba9;
}
.contact_color{
    color: rgb(117, 109, 204);
}
.learn_section{
    padding-bottom: 100px;
}

.home_page_select_box {
  height: 45px!important;
}
.child_calulator_card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 4px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}






