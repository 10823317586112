.range-slider__value3 {
  display: inline-block;
  position: relative;
  width: 90px;
  color: #000;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  padding: 5px 10px;
  margin-left: 8px;
}
.range-slider__value3:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid rgb(125, 115, 210);
  border-bottom: 7px solid transparent;
  content: '';
}
