
.range-slider__value2 {
  display: inline-block;
  position: relative;
  width: 45px;
  color: #000;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0px;
  margin-left: 8px;
}
.range-slider__value2:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid rgb(125, 115, 210);
  border-bottom: 7px solid transparent;
  content: '';
}

div#calculator  input[type=message]{
	background-color: white!important;
}

.errorMsg{
	color: red;
}

.body2{
	
	background-color: white;
}
