@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ipco9f');
  src:  url('../fonts/icomoon.eot?ipco9f#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ipco9f') format('truetype'),
    url('../fonts/icomoon.woff?ipco9f') format('woff'),
    url('../fonts/icomoon.svg?ipco9f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wallet:before {
  content: "\e911";
}
.icon-diversity:before {
  content: "\e912";
}
.icon-liquidity:before {
  content: "\e909";
}
.icon-analysis:before {
  content: "\e90a";
}
.icon-network:before {
  content: "\e90b";
}
.icon-parachute:before {
  content: "\e90c";
}
.icon-piggy-bank:before {
  content: "\e90d";
}
.icon-accounting:before {
  content: "\e90e";
}
.icon-taxes:before {
  content: "\e90f";
}
.icon-success:before {
  content: "\e910";
}
.icon-time-money:before {
  content: "\e900";
}
.icon-goal:before {
  content: "\e901";
}
.icon-graphic:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-mail:before {
  content: "\e904";
}
.icon-smartphone:before {
  content: "\e905";
}
.icon-rocket:before {
  content: "\e906";
}
.icon-idea:before {
  content: "\e907";
}
.icon-insurance:before {
  content: "\e908";
}

.purple-circle{
  background: #7A7FE3;
  color: #fff;
  display: inline-block;
  padding: 30px 0;
  border-radius: 70px;
  width: 130px;
  margin-bottom: 30px;
}

.white-circle{
  background: #FFF;
  color: #7A7FE3;
  display: inline-block;
  padding: 30px 0;
  border-radius: 70px;
  width: 130px;
  margin-bottom: 30px;
}
