.form_style{
	max-width: 630px;
	margin: auto;
}
.section-wrapper2 {
	max-width: 500px;
	height: 430px;
	margin: auto;
	position: relative;
	background-color: white;
  }