.login {
    max-width: 530px;
    margin: 50px auto 0;
}
.range-slider {
  margin: 0px 0px 59px 0%
}
.range-slider {
  width: 97%;
}
.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (103px));
  height: 10px;
  border-radius: 5px;
  background: rgb(0, 0, 0);
  outline: none;
  padding: 0;
  margin: 0;
}
.range-slider__range::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(125, 115, 210);
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-webkit-slider-thumb:hover {
  background: rgb(125, 115, 210);
}
.range-slider__range:active::-webkit-slider-thumb {
  background: rgb(125, 115, 210);
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: rgb(125, 115, 210);
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-moz-range-thumb:hover {
  background: rgb(125, 115, 210);
}
.range-slider__range:active::-moz-range-thumb {
  background: rgb(125, 115, 210);
}
.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px rgb(125, 115, 210);
}
.range-slider__value {
  display: inline-block;
  position: relative;
  width: 45px;
  color: #000;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0px;
  margin-left: 8px;
}
.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid rgb(125, 115, 210);
  border-bottom: 7px solid transparent;
  content: '';
}
::-moz-range-track {
  background: rgb(125, 115, 210);
  border: 0;
}
input::-moz-focus-inner, input::-moz-focus-outer {
  border: 0;
}

.form-body{
	max-width: 650px;
	margin: auto;
}
.form-body2{
  max-width: 650px;
  height: 350px;
	margin: auto;
}
.m-b-20 {
    margin-bottom: 20px!important;
}
.image_style {
	margin-top: 10px;
    max-width: 70%;	
}
.errorMsg{
	color: red;
}
.settings-wrapper {
  width: 100%;
  height:600px;
  overflow: hidden;
}

.header {
  height: 200px;
  background-color: white;
  line-height: 5vh;
  box-sizing: border-box;
}

.list {
  margin: 10px;
  padding: 5px 10px;
  font-size: 3.5vh;
  background-color: white;
  list-style-type: none;
  box-sizing: border-box;
}

.link {
  cursor: pointer;
}

.font-settings-content {
  padding: 20px;
  box-sizing: border-box;
}

.section-wrapper {
  max-width: 650px;
  height: 420px;
  margin: auto;
  position: relative;
  background-color: white;
}

/*These classes are responsible for animation*/
.is-start__current {
  left: 100%;
}

.is-animate__previous, .is-animate__current, .is-animate__previous--inverse, .is-animate__current--inverse {
  transition: all .2s ease;
}

.is-animate__previous, .is-animate__current {
  transform: translate3d(-100%, 0, 0);
}

.is-start__current--inverse {
  left: -100%;
}

.is-animate__previous--inverse, .is-animate__current--inverse {
  transform: translate3d(100%, 0, 0);
}
