.navbar{
  min-height: 70px;
  padding-top: 13px;
}
body{
  background: url("../Images/bg_page.png");
}
section{
  padding-top: 100px;
  padding-bottom: 100px;
}
.text-purple{color: #332ba9;}
.tagline{
  background: url("../Images/graph.png") no-repeat bottom;
  background-size: cover ;
  padding: 150px 0 350px 0;
}
.services{
  background-color: white;
}
.nav_style{
	float: none;
	font-size: 20px;
}
.link_colour{
	color: #332ba9;
	font-size: 15px;
}

footer{
  background-color:#D5D8FB;
  border-top: 2px solid rgb(117, 109, 204)
}
.text_col{
	color: rgb(117, 109, 204);
}
.icon{
	font-size: 24px;
	color: rgb(117, 109, 204);
}

.footer_body{
	vertical-align: center;
}
.map-responsive{
    overflow:hidden;
    padding-bottom:50%;
    position:relative;
    height:0;
	  -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
.center1{
    display: block;
    margin-left: auto;
    margin-right: auto;
	  width:250px;
}
.para{
	text-align: center;
}
.expert{
	background-color: #D5D8FB;
}
.contact_page{
  background-color: #222649;
}
.contact{
	color: white;
}
.client_testi{
	color: #7A7FE3;
	font-size: 90px;
}

div#main-registration-container form  input[type=email], input[type=submit], input[type=message] input[type=text]{
	background-color: white;
}

.form_font_size {
	font-size: 21px;
}

h1, h2, h3, h4, h5, .display-1, .display-2, .display-3, .display-4{
  font-family: IBM Plex Serif;
}

.avatar{
  border-radius: 150px;
}
.bg-light{
	background-color: #ddd!important;
}


